<template>
    <div v-if='model && !model.is_null'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div>
                                <span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    :class="{'p-invalid': v$.model.publish_state.$invalid && v$.model.publish_state.$dirty}"
                                    placeholder="Stato di pubblicazione" />
                            </div>
                        </template>
                    </Toolbar>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='t_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug = row.slug'/>
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug = sluggify(model.title)'/>
                        <label for='t_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$errors.length}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='subtitle'
                                   type='text'
                                   v-model="model.subtitle"
                        />
                        <label for='subtitle'>Sottotitolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description'
                        :key='"cont_sk_desc_"+id+"_"+lang'
                        toolbar='essential'
                        contentType='html'/>
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.benefits'
                        :key='"cont_sk_bene_"+id+"_"+lang'
                        toolbar='essential'
                        contentType='html'/>
                    <label>Benefits</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.guest_desc'
                        :key='"cont_skill_gd_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Descrizione per SEO</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.img"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.img' class='text-center'>
                        <img class="mt-4" :src='model.img' alt='Model Image' :height='uppyDashboardProps.height-50'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <RelatedBooks
                        title='Libri che concorrono ad assegnare la skill'
                        :books='model.books'
                        @book-added='addBook'
                        @book-removed='removeBook'/>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Skills');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../libs/tools';
import model from './model';
import RelatedBooks from '../../../components/4books/RelatedBooks';

export default {
    components: {
        RelatedBooks
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        addBook(book){
            if (!book || !book.translations || !book.translations[this.lang]){
                this.ShowError("ATTENZIONE", "La traduzione di questo libro nella lingua della skill non esiste.");
                return;
            }
            this.model.books.push(book)
        },
        removeBook(book){
            this.model.books = this.model.books.filter(b => b.id !== book.id);
        },
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }


            this.saveTranslation({
                data: this.model,
                parent_id: model.translationParentId(this.model, this.id)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Skill salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(mod.translations[this.lang], base);
                if (!this.model.books) this.model.books = [];
                return;
            }

            this.model = base;
        }
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: Number
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ]
}
</script>
